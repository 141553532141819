<template>
  <div class="prozess-signature-request">
    <b-modal
      :id="modal"
      :ref="modal"
      :visible="visible"
      hide-footer
      centered
      no-fade
      no-close-on-backdrop
      no-close-on-esc
      size="lg"
      hide-header-close
      :title="this.$t(modalTitle)"
    >
      <b-overlay
        :show="loading"
        spinner-variant="primary"
        rounded="sm"
        variant="transparent"
      >
        <form @submit.prevent="save">
          <prozess-input
            v-model="resource.title"
            class="mt-1"
            :placeholder="$t('Title')"
            icon="TypeIcon"
            field="title"
            name="title"
            :error="$hasError('title')"
          />
          <div class="d-flex flex-column prozess-signature-request__signatories">
            <span class="prozess-signature-request__signatories-title">{{ $t('Signatories') }}</span>
            <span>{{ $t('Add signatories to document or search through your contacts') }}</span>
          </div>
          <div class="prozess-signature-request__signatory-detail d-flex mb-1">
            <div style="flex: 1; margin-right: 0.5rem;">
              <div class="d-flex">
                <prozess-input
                  v-model="signatory.firstName"
                  style="flex: 1;"
                  class="mr-1"
                  :placeholder="$t('First Name')"
                  icon="UserIcon"
                  field="name"
                  name="name"
                  :error="$hasError('firstName')"
                />
                <prozess-input
                  v-model="signatory.lastName"
                  style="flex: 1;"
                  :placeholder="$t('Last Name')"
                  field="name"
                  name="name"
                  :error="$hasError('lastName')"
                />
              </div>
              <prozess-input
                v-model="signatory.mobileNumber"
                :placeholder="$t('Mobile')"
                field="mobileNumber"
                name="mobileNumber"
                :error="$hasError('mobileNumber')"
              />
              <prozess-input
                v-model="signatory.email"
                class="mt-1"
                :placeholder="$t('Email')"
                field="email"
                name="email"
                :error="$hasError('email')"
              />
              <b-button
                :disabled="!enableNewSignatory"
                class="w-100"
                @click="addSignatory(signatory)"
              >
                <span class="align-middle">{{ $t('Add') }}</span>
              </b-button>
            </div>
            <div
              style="flex: 1; margin-left: 0.5rem;"
            >
              <contact-search
                v-model="contact"
                class="mb-2"
              />
              <div
                ref="signatoryTags"
                class="prozess-signature-request__signatory-tags"
              >
                <div
                  v-for="tag of signatories"
                  :key="tag.tagUuid"
                  class="prozess-signature-request__signatory-tag"
                >
                  <div class="mr-1">
                    <div class="prozess-signature-request__signatory-tag-name">
                      <span>{{ tag.fullName || (tag.firstName || '') + ' ' + tag.lastName }}</span>
                    </div>
                    <span class="prozess-signature-request__signatory-tag-email">{{ tag.email }}</span>
                  </div>
                  <div class="prozess-signature-request__signatory-tag-remove">
                    <feather-icon
                      icon="XIcon"
                      @click="remove(tag.tagUuid)"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-2">
            <prozess-field-wrapper
              :error="$hasError('description')"
            >
              <b-form-textarea
                v-model="resource.description"
                :placeholder="$t('Description')"
                rows="3"
              />
            </prozess-field-wrapper>
            <div class="d-flex justify-content-between">
              <prozess-field-wrapper
                style="flex: 1; "
                :error="$hasError('activeTo')"
              >
                <b-form-datepicker
                  v-model="resource.activeTo"
                  :locale="$i18n.locale"
                  :placeholder="$t('Active Until')"
                />
              </prozess-field-wrapper>
              <prozess-field-wrapper
                style="flex: 1; margin-left: 1rem;"
                :error="$hasError('deleteOn')"
              >
                <b-form-datepicker
                  v-model="resource.deleteOn"
                  :locale="$i18n.locale"
                  :placeholder="$t('Delete on')"
                />
              </prozess-field-wrapper>
            </div>
            <div>
              <user-search
                v-model="postingAdmin"
                class="w-50"
                style="padding-right: 0.5rem;"
              />
            </div>
          </div>
          <div class="d-flex justify-content-end">
            <b-button
              class="mr-1"
              variant="outline-secondary"
              @click="close"
            >
              <span class="align-middle">{{ $t('Cancel') }}</span>
            </b-button>
            <b-button
              variant="primary"
              :disabled="saving"
              type="submit"
            >
              <template>
                <b-spinner
                  v-if="saving"
                  small
                  class="mr-1"
                />
              </template>
              <span class="align-middle">{{ $t('Send') }}</span>
            </b-button>
          </div>
        </form>
      </b-overlay>
    </b-modal>
  </div>
</template>

<script>
import signatureService from '@/services/signature'
import ContactSearch from './ContactSearch.vue'
import UserSearch from './UserSearch.vue'

const defaultSignatory = {
  firstName: '',
  lastName: '',
  mobileNumber: '',
  email: '',
}

const defaultRequestSignature = {
  title: '',
  description: '',
  activeTo: null,
  deleteOn: null,
  postingAdmin: '',
  senderName: '',
  documents: [],
  signatories: [],
}

export default {
  components: {
    ContactSearch,
    UserSearch,
  },
  props: {
    document: {
      type: Object,
      required: true,
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      modal: 'signatureRequestForm',
      modalTitle: 'Signature Request',
      loading: false,
      saving: false,
      errors: [],
      signatory: JSON.parse(JSON.stringify(defaultSignatory)),
      resource: {},
      contact: null,
      signatories: [],
      postingAdmin: null,
      signantSettings: null,
    }
  },
  computed: {
    enableNewSignatory() {
      const {
        firstName, lastName, email, mobileNumber,
      } = this.signatory
      return firstName.trim() && lastName.trim() && email.trim() && mobileNumber.trim()
    },
  },
  watch: {
    visible(value) {
      if (value) {
        this.reset()
        this.getInitialData()
      }
    },
    contact(contact) {
      if (contact) {
        this.addSignatory({ ...contact.value })
        this.$nextTick(() => {
          this.contact = null
        })
      }
    },
  },
  methods: {
    async getInitialData() {
      this.loading = true

      try {
        const res = await signatureService.getSignatureDefault(this.document.uuid)
        const {
          defaultActiveToDate, defaultWillBeDeletedOnDate, defaultDescription, senderName, defaultOwner, attachedContacts,
        } = res.data
        this.resource.title = this.document.name
        this.resource.activeTo = this.$dayjs(defaultActiveToDate).format('YYYY-MM-DD')
        this.resource.deleteOn = this.$dayjs(defaultWillBeDeletedOnDate).format('YYYY-MM-DD')
        this.resource.description = defaultDescription
        this.resource.senderName = senderName
        this.postingAdmin = {
          isNew: false,
          label: `${defaultOwner.firstName} ${defaultOwner.lastName}`,
          value: defaultOwner.uuidKey,
        }
        this.signatories = attachedContacts.map(contact => ({
          uuid: contact.uuid,
          tagUuid: contact.uuid,
          email: contact.email,
          fullName: contact.name,
        }))
      } finally {
        this.loading = false
      }
    },
    prepareSignatoryData() {
      return this.signatories.map(item => {
        const data = {
          priority: 0,
        }
        if (item.uuid) {
          data.existingContact = item.uuid
        } else {
          data.externalUser = item
        }
        return data
      })
    },
    async save() {
      await this.validate()
      if (this.errors.length > 0) return

      this.saving = true
      this.resource.signatories = this.prepareSignatoryData()
      this.resource.documents = [
        {
          uuid: this.document.uuid,
          versionId: this.document.documentVersion,
          description: this.document.description,
        },
      ]

      signatureService.requestSignature({
        ...this.resource,
        description: this.resource.description ? this.resource.description.trim() : null,
        activeTo: this.$dayjs(this.resource.activeTo).endOf('day').toISOString(),
        deleteOn: this.$dayjs(this.resource.deleteOn).toISOString(),
        postingAdmin: this.postingAdmin ? this.postingAdmin.value : null,
      })
        .then(res => {
          this.$emit('saved')
          this.close()
        })
        .catch(err => {
          this.errors = err.response.data.errors
        })
        .finally(() => {
          this.saving = false
        })
    },
    remove(tagUuid) {
      this.signatories = this.signatories.filter(item => item.tagUuid !== tagUuid)
    },
    resetSignatory() {
      this.signatory = JSON.parse(JSON.stringify(defaultSignatory))
    },
    async validateSignatory() {
      const yup = this.$yup
      const schema = yup.object().shape({
        firstName: yup.string().required().nullable().max(100, `${this.$t('Max')} 100`),
        lastName: yup.string().required().nullable().max(100, `${this.$t('Max')} 100`),
        mobileNumber: yup.string().required().nullable().max(100, `${this.$t('Max')} 100`),
        email: yup.string().required().nullable().email(),
      })
      await schema.validate(this.signatory, {
        abortEarly: false,
      })
        .catch(err => {
          this.errors = err.inner.map(e => ({
            field: e.path,
            defaultMessage: e.message,
          }))
        })
    },
    async validate() {
      if (!this.signatories.length) {
        this.$swal({
          title: this.$t('You should add a signatory'),
          icon: 'warning',
          confirmButtonText: this.$t('Close'),
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
          allowOutsideClick: false,
        })
        return
      }

      this.errors = []
      const yup = this.$yup
      const schema = yup.object().shape({
        title: yup.string().required().nullable().max(100, `${this.$t('Max')} 100`),
        description: yup.string().required().nullable(),
        activeTo: yup.date().required().nullable()
          .max(this.$dayjs().add(364, 'day').toDate(), 'Max of 364 days'),
        deleteOn: yup.date().required().nullable()
          .max(this.$dayjs().add(365, 'day').toDate(), 'Max of 365 days'),
      })
      await schema.validate(this.resource, {
        abortEarly: false,
      })
        .catch(err => {
          this.errors = err.inner.map(e => ({
            field: e.path,
            defaultMessage: e.message,
          }))
        })
    },
    async addSignatory(data) {
      this.errors = []
      if (data.uuid) {
        if (this.signatories.find(item => data.uuid === item.uuid)) { return }
      } else {
        await this.validateSignatory()
        if (this.errors.length) return

        if (this.signatories.find(item => data.email === item.email)) {
          this.errors.push({
            field: 'email',
            defaultMessage: 'Email already exist',
          })
          return
        }
      }

      this.signatories.push({
        ...data,
        tagUuid: this.$uuidv4(),
      })
      this.resetSignatory()
      setTimeout(() => {
        this.$refs.signatoryTags.scrollTop = this.$refs.signatoryTags.scrollHeight
      }, 100)
    },
    close() {
      this.reset()
      this.$emit('close')
    },
    reset() {
      this.errors = []
      this.resource = JSON.parse(JSON.stringify({}))
      this.signatory = JSON.parse(JSON.stringify(defaultSignatory))
      this.contact = null
      this.postingAdmin = null
      this.signatories = []
    },
    showForm() {
      this.$bvModal.show('signatureRequestForm')
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/master-variables.scss';

.prozess-signature-request {
  &__signatory-detail {
    border-bottom: 1px solid $colour--text-muted;
    padding-bottom: 2rem;
  }
  @media screen and (max-width: 425px) {
    &__signatory-detail {
      flex-direction: column;
      align-items: unset;
      button {
        margin-bottom: 1rem;
      }
    }
  }
  &__signatories {
    border-bottom: 1px solid $colour--text-muted;
    padding-bottom: 0.5rem;
    margin-bottom: 2rem;

    &-title {
      font-size: 1.2rem;
    }
  }

  &__signatory-tags {
    height: 145px !important;
    background-color: var(--colour--body-bg);
    border-radius: 0.357rem ;
    padding: 1rem;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    overflow: auto;
  }

  &__signatory-tag {
    display: flex;
    padding: 0.8rem;
    border-radius: 17px;
    background: darken($colour--text-muted, 10%);
    align-items: center;
    &-email {
      font-size: 0.8rem;
    }
    margin-right: 1rem;
    margin-bottom: 1rem;
    &-remove {
      cursor: pointer;
    }
  }
}
</style>
