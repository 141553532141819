<template>
  <div>
    <b-overlay
      :show="loading"
      spinner-variant="primary"
      rounded="sm"
      variant="transparent"
      style="height: 100%;"
      class="d-flex flex-column"
    >
      <div class="card resource-view__scrollable">
        <div class="card-body">
          <more-info-modal
            :signant-request-details-id="signantRequestDetailsId"
            :active-signant-request="activeSignantRequest"
          />
          <p v-if="!items.length && !loading">
            {{ $t('No signature requests') }}
          </p>
          <div
            v-for="item of items"
            :key="item.uuid"
            class="prozess-signature-requests__item pb-1 mb-2"
          >
            <div class="d-flex justify-content-between mb-2">
              <!-- More Info -->
              <div
                class="prozess-signature-requests__more-info"
                @click="handleMoreInfo(item)"
              >
                <div class="prozess-signature-requests__more-info-question-mark">
                  ?
                </div>
                <span>{{ $t("More Info") }}</span>
              </div>
              <!-- Delete Signing Request -->
              <button
                :id="item.uuid + '-delete'"
                class="btn btn-primary d-flex justify-content-around align-items-center btn-secondary"
                :style="{
                  'opacity': item.isDeleteEnabled() ? 1 : 0.4
                }"
                @click="deleteSigningRequest(item)"
              >
                <span>{{ $t('Delete Signing Request') }}</span>
              </button>
              <b-tooltip
                :target="item.uuid + '-delete'"
                placement="left"
              >
                {{ item.isDeleteEnabled() ? $t('Delete') : $t(`You cant delete this signature request`) }}
              </b-tooltip>
            </div>
            <div
              v-for="signatory of item.signatories"
              :key="signatory.email"
              class="prozess-signature-requests__contact d-flex justify-content-between"
            >
              <div style="flex: 1" class="d-flex">
                <div style="width: 250px;">
                  {{ signatory.firstName }} {{ signatory.lastName }}
                  <span v-if="!signatory.contactUuid && signatory.deleted">({{ $t('Deleted') }})</span>
                </div>
                <div>
                  {{ signatory.getRejectReason() }}
                </div>
              </div>
              <span
                v-if="signatory.getSignatoryResponse()"
                :style="{
                  'color': actionStatusColor[signatory.getSignatoryResponse().actionStatus]
                }"
              >{{ signatory.getSignatoryResponse().message }}</span>
            </div>
          </div>
        </div>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import { swalConfirmDeleteOption } from '@/helpers/app'
import signatureService from '@/services/signature'
import RootEvents from '@/constants/rootEvents'
import MoreInfoModal from './MoreInfoModal.vue'

export default {
  components: {
    MoreInfoModal,
  },
  props: {
    document: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      resourceId: null,
      companyId: null,
      showForm: false,
      loading: false,
      items: [],
      customFields: [],
      activeSignantRequest: null,
      signantRequestDetailsId: 'signant-request-details',
      actionStatusColor: {
        Rejected: '#FF6710',
        Completed: '#00FB87',
      },
    }
  },
  created() {
    this.getInitialData()
    this.$root.$on(RootEvents.SIGNATURE_REQUEST_SAVED, () => {
      this.getInitialData()
    })
  },
  methods: {
    handleMoreInfo(item) {
      this.activeSignantRequest = item
      this.$bvModal.show(this.signantRequestDetailsId)
    },
    deleteSigningRequest(item) {
      if (!item.isDeleteEnabled()) return
      this.$swal(swalConfirmDeleteOption(v => this.$t(v))).then(result => {
        if (result.value) {
          signatureService.deleteSignatureRequest(item.uuid).then(() => {
            this.getInitialData()
          })
        }
      })
    },
    viewDocument(id) {
      if (!this.$can('Read', 'Document')) return
      this.$router.push({
        name: 'document-view',
        params: {
          id,
        },
      })
    },
    edit(item) {
      this.resourceId = item.uuid
      this.showForm = true
    },
    async getInitialData() {
      try {
        this.loading = true
        this.items = []

        const documentId = this.$route.params.id
        const res = await signatureService.getSignatureRequests(documentId)
        this.items = res.data

        this.items = this.items.reduce((acc, item) => {
          let { signatories } = item

          item.isDeleteEnabled = () => item.documents[0].signatoryResponse.length === 0 && this.$can('Delete', 'Signature')

          signatories = signatories.map(signatory => {
            const response = item.documents[0].signatoryResponse.find(_response => _response.signatoryId === signatory.signatoryId)
            signatory.getRejectReason = () => (response ? response.rejectReason : '')
            signatory.getSignatoryResponse = () => {
              if (response) {
                response.message = this.$t(response.actionStatus)
                if (response.actionStatus === 'Completed') {
                  response.message = `${this.$t('Signed on')} ${this.$d(new Date(response.lastActionTimestamp), 'short')}`
                }
              }
              return response
            }
            return signatory
          })

          item.signatories = signatories
          acc.push(item)
          return acc
        }, [])
      } finally {
        this.loading = false
      }
    },
    collapse(item) {
      this.$set(item, 'visible', !item.visible)
    },
    create() {
      this.showForm = !this.showForm
    },
    onCloseForm() {
      this.resourceId = null
      this.showForm = false
    },
  },
}
</script>

<style lang="scss">
@import "@/assets/scss/variables/_variables.scss";
@import "@/assets/scss/company-contact-list.scss";
@import '@/assets/scss/master-variables.scss';

.prozess-signature-requests {
  &__more-info {
    background: var(--colour--body-bg);
    display: flex;
    border-radius: 30px;
    font-weight: bold;
    color: $colour--white;
    align-items: center;
    padding-right: 1rem;
    user-select: none;
    cursor: pointer;

    &-question-mark {
      background: var(--colour--secondary);
      border-radius: 50%;
      height: 100%;
      width: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 1rem;
      font-size: 1.3rem;
    }
  }
  &__contact {
    background: var(--colour--table-row);
    border-radius: 10px;
    padding: 1rem 2rem;
    margin-bottom: 1rem;
  }
  &__item {
    border-bottom: 1px solid $color--gray-y;
  }
}
</style>
