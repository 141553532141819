<template>
  <div>
    <prozess-search-dropdown
      v-model="data"
      :placeholder="$t('Search and add contact')"
      :label="label || $t('CONTACT')"
      :loading="contactSearchLoading"
      :options="contacts"
      icon="UserIcon"
      :error="error"
      :hint="hint"
      :disabled="disabled"
      @search="handleSearch"
      @clear="handleClear"
      @blur="handleBlur"
    />
  </div>
</template>

<script>
import contactService from '@/services/contact'

export default {
  props: {
    initialOptions: {
      type: Array,
      default: () => [],
    },
    label: {
      default: null,
      type: String,
    },
    value: {
      default: null,
      type: [String, Number, Object],
    },
    hint: {
      type: String,
      default: null,
    },
    error: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      data: this.value,
      contacts: [],
      contactSearch: '',
      contactSearchLoading: false,
    }
  },
  watch: {
    initialOptions: {
      handler(value) {
        this.contacts = value
      },
      immediate: true,
    },
    data() {
      this.$emit('input', this.data)
    },
    value(data) {
      this.data = data
    },
  },
  methods: {
    handleClear() {
      this.contacts = []
      this.$emit('input', null)
    },
    handleBlur() {
      this.contacts = []
    },
    handleSearch(search) {
      if (search.trim() === '') return
      this.contactSearchLoading = true

      contactService.autocomplete(search.trim())
        .then(res => {
          this.contacts = res.data.map(item => ({
            label: `${item.firstName || ''} ${item.lastName}`,
            selectedLabel: item.firstName || this.$t('(No first name)'),
            additionalLabels: ['email', 'connectedCompanies.name'],
            value: item,
          }))
        })
        .finally(() => {
          this.contactSearchLoading = false
        })
    },
  },
}
</script>
