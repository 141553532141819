<template>
  <div>
    <prozess-search-dropdown
      v-model="data"
      :placeholder="$t('Default Admin')"
      :loading="loading"
      :can-handle-new-item="false"
      :options="items"
      icon="UserIcon"
      :error="error"
      :hint="hint"
      :disabled="disabled"
      @search="handleSearch"
      @clear="handleClear"
      @blur="handleBlur"
    />
  </div>
</template>

<script>
import signatureService from '@/services/signature'

export default {
  props: {
    label: {
      default: null,
      type: String,
    },
    value: {
      default: null,
      type: [String, Number, Object],
    },
    hint: {
      type: String,
      default: null,
    },
    error: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      data: this.value,
      items: [],
      loading: false,
    }
  },
  watch: {
    data() {
      this.$emit('input', this.data)
    },
    value(data) {
      this.data = data
    },
  },
  methods: {
    handleClear() {
      this.items = []
      this.$emit('input', null)
    },
    handleBlur() {
      this.items = []
    },
    handleSearch(search) {
      if (search.trim() === '') return
      this.loading = true

      signatureService.searchEmployee(search.trim())
        .then(res => {
          this.items = res.data.map(item => ({
            label: `${item.firstName || ''} ${item.lastName}`,
            value: item.userUuid,
          }))
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>
