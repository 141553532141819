<template>
  <div>
    <b-overlay
      :show="loading"
      spinner-variant="primary"
      rounded="sm"
      variant="transparent"
      style="height: 100%;"
      class="d-flex flex-column"
    >
      <div class="card resource-view__scrollable">
        <div class="card-body">
          <div class="document-preview__upper-buttons mb-2 d-flex justify-content-between">
            <input
              ref="newFileInput"
              type="file"
              style="display: none"
              @change="handleNewFileInputChange"
            >
            <button
              id="create-button"
              class="btn btn-primary d-flex justify-content-around align-items-center btn-secondary"
              :class="{
                'icon-disabled': $can('Update', 'Document') === false
              }"
              @click="$can('Update', 'Document') && uploadNewVersion()"
            >
              <feather-icon
                icon="UploadIcon"
                class="mr-50"
              />
              <span>{{ $t('Upload new version') }}</span>
            </button>
            <b-tooltip target="create-button">
              {{ $can('Update', 'Document') === true ? $t('Upload new version') : $t('You dont have permission to uplaod new version') }}
            </b-tooltip>
            <div class="document-preview__select-version">
              <prozess-select
                v-model="selectedVersion"
                :placeholder="$t('Select version')"
                :options="previousVersions"
                :clearable="false"
                :reduce="option => option.value"
              />
            </div>
          </div>
          <div>
            <object
              v-if="$lodash.get(attachment, 'mimeType') === 'application/pdf'"
              ref="attachmentElement"
              style="width: 100%; height: 100vh"
              :data="attachment.url"
              type="application/pdf"
            />
            <div v-else>
              <p class="text-center mt-5">
                {{ $t('No preview available') }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import documentService from '@/services/document'

export default {
  props: {
    document: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      currentVersion: null,
      attachment: null,
      selectedVersion: null,
    }
  },
  computed: {
    previousVersions() {
      return [
        { value: null, label: this.$t('Select version') },
        ...(this.document.versions || []).map(version => ({
          value: version.id,
          label: this.$d(new Date(version.uploadedTimestamp), 'long'),
        })),
      ]
    },
  },
  watch: {
    document: {
      handler(value) {
        this.selectedVersion = this.$lodash.get(value, 'versions.0.id')
        this.attachment = value.directUrlLatestVersion
      },
      immediate: true,
    },

    selectedVersion: {
      handler(value) {
        if (value) {
          this.getDirectUrl(value)
        }
      },

      immediate: true,
    },
  },
  methods: {
    handleNewFileInputChange(e) {
      const maxFileSize = 10
      const [file] = e.target.files
      if (file.size / 1024 / 1024 > maxFileSize) {
        this.$swal({
          title: `${this.$t('File should not exceed')} ${maxFileSize}mb`,
          icon: 'warning',
          confirmButtonText: this.$t('Close'),
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
          allowOutsideClick: false,
        })
      } else {
        this.upload(file)
      }
    },
    prepareFile(formName = 'form', file) {
      const formData = new FormData()
      delete this.document.mimeType
      this.document.fileName = file.name
      formData.append(formName, new Blob([JSON.stringify(this.document)], {
        type: 'application/json',
      }))
      formData.append('file', file)
      return formData
    },
    upload(file) {
      this.loading = true
      return documentService.updateWithNewFile(this.document.uuid, this.prepareFile('newVersionForm', file))
        .then(res => {
          this.$emit('update')
        })
        .finally(() => {
          this.loading = false
        })
    },
    uploadNewVersion() {
      this.$refs.newFileInput.click()
    },
    getDirectUrl(versionId) {
      this.loading = true
      setTimeout(() => {
        documentService.directUrl(this.document.uuid, versionId)
          .then(res => {
            this.attachment = res.data
            if (this.attachment.mimeType === 'application/pdf') {
              this.$refs.attachmentElement.data = this.attachment.url
            }
          })
          .finally(() => {
            this.loading = false
          })
      }, 3000)
    },
  },
}
</script>

<style lang="scss">
@import "@/assets/scss/company-contact-list.scss";

.document-preview {

  &__select-version {
    width: 240px;
    @media screen and (max-width: 425px) {
      margin-top: 1rem;
      width: 100%;
    }
  }

  &__upper-buttons {
    @media screen and (max-width: 425px) {
      flex-direction: column;
    }
  }
}
</style>
