<template>
  <b-modal
    :id="signantRequestDetailsId"
    hide-footer
    centered
    no-fade
    size="lg"
    :title="$t('Signing Request Information')"
  >
    <div
      v-if="activeSignantRequest"
      class="m-1"
    >
      <div
        v-for="key of Object.keys(details)"
        :key="key"
        class="d-flex mb-2"
      >
        <span
          style="flex: 1"
          class="prozess-more-info-modal__title"
        >{{ $t(key) }}</span>
        <span
          style="flex: 2"
          :style="{
            'color': activeAndDeleteColor[key]
          }"
        >{{ details[key] }}</span>
      </div>
    </div>
    <div
      class="d-flex justify-content-end"
      @click="$bvModal.hide(signantRequestDetailsId)"
    >
      <button class="btn btn-dark">
        {{ $t('Dismiss') }}
      </button>
    </div>
  </b-modal>
</template>

<script>
export default {
  filters: {
    positiveOnly(value) {
      return value < 0 ? 0 : value
    },
  },
  props: {
    signantRequestDetailsId: {
      type: String,
      required: true,
    },
    activeSignantRequest: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      activeAndDeleteColor: {
        'Active To': '#00FB87',
        'Will delete in': '#FF6710',
      },
    }
  },
  computed: {
    details() {
      const {
        title, created, activeUntil, toBeDeletedIn, description, firstName, lastName, postingAdmin,
      } = this.activeSignantRequest
      const postingAdminDeleted = postingAdmin.deleted ? `(${this.$t('Deleted')})` : ''
      return {
        Title: title,
        'Date Created': this.$d(new Date(created), 'short'),
        'Active To': this.$d(new Date(activeUntil), 'short'),
        'Will delete in': this.$d(new Date(toBeDeletedIn), 'short'),
        Description: description,
        'Posting Admin': `${postingAdmin.firstName} ${postingAdmin.lastName} ${postingAdminDeleted}`,
      }
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/master-variables.scss';

.prozess-more-info-modal {
  &__title {
    color: $colour--text-muted;
  }
}
</style>
